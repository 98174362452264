import React from 'react'
import Header from '../layout/header'
import Footer from '../layout/footer'
import HairRemoval from './HairRemoval'
import HairRemovalSection from './hairRemovalSection'
import TreatmentAreas from './TreatmentAreas'
import HairRemovalBenefits from './HairRemovalBenefits'
import HairRemovalND from './HairRemovalND'

const LaserHairRemoval = () => {
  return (
    <div>
        <Header/>
        <HairRemoval />
        <HairRemovalSection/>
        <TreatmentAreas/>
        <HairRemovalND/>
        <HairRemovalBenefits/>
        <Footer/>
    </div>
  )
}

export default LaserHairRemoval