import React from 'react'
import Do from '../common/Do'
import image from '../../assets/hair/asthetic.png'

const HairRemovalBenefits = () => {
    const data = [
        {
            description: `Consultation: Our expert team will assess your skin type, hair color, and treatment goals.`,
        },
        {
            description: `Treatment Sessions: Typically, a series of sessions spaced a few weeks apart is recommended.`,
        },
        {
            description: `Post-Treatment Care: Follow our guidelines to ensure the best results.`,
        },
    ]
    return (
        <div>
            {/* <p className="text-center text-[28px] md:text-[33px] lg:text-[40px] leading-[34px] md:leading-[50px] font-semibold">
                Who is the Ideal Candidate for Blepharoplasty?
            </p> */}
            <div className="py-2 sm:py-5 ">
                <Do
                    mainTitle='What to Expect'
                    titleNotNeeded pointsCentered
                    data={data}
                    backgroundImage={image}
                    action="BOOK APPOINTMENT NOW"
                    buttonNotNeeded
                    isReversed={true}
                    bulletImagesNotNeeded={true}
                />
            </div>
        </div>
    )
}

export default HairRemovalBenefits
