import React from 'react'
import NumericBullets from '../common/NumericBullets'
import image1 from '../../assets/laserHairRemoval/beautiful-caucasian-woman-shaving-her-legs-with-knife-with-shaving-foam-white-background.jpg'
import image2 from '../../assets/laserHairRemoval/hair-removal-2.jpg'

const TreatmentAreas = () => {
    const description2 = [
        {
            id: '1',
            description:
                "Face: Say goodbye to upper lip hair, chin hair, and unwanted facial fuzz.",
        },
        {
            id: '2',
            description:
                'Underarms: Ditch the razor and enjoy smooth underarms.',
        },
        {
            id: '3',
            description:
                'Bikini Line: Get ready for beach season with a clean bikini line.',
        },
        {
            id: '4',
            description:
                'Legs: Say farewell to shaving your legs regularly.',
        },
        {
            id: '5',
            description:
                'Back and Chest: Men can benefit from hair removal on the back and chest too.',
        },
        {
            id: '6',
            description:
                'Intimate Parts: Achieve hair-free results in intimate areas.',
        },
        {
            id: '7',
            description:
                'Eyebrows: Perfectly shaped brows without the hassle of tweezing or threading.',
        },
    ]
    const description = [
        {
            id: '8',
            description:
                'Arms: Smooth arms for a confident look.',
        },
        {
            id: '9',
            description:
                'Neck: Say goodbye to unwanted neck hair.',
        },
        {
            id: '10',
            description:
                'Stomach: Flaunt a hair-free stomach area.',
        },
        {
            id: '11',
            description:
                'Lips: Remove upper lip hair effectively.',
        },
        {
            id: '12',
            description:
                'Ears: Get rid of ear hair discreetly.',
        },
        {
            id: '13',
            description:
                'Feet: Say no to foot hair.',
        },
        {
            id: '14',
            description:
                'Male Body: Men can enjoy hair-free chests, backs, and more.',
        },
    ]
    return (
        <>
            <div className="py-2">
                <div className="py-2 md:py-14 md:pr-16">
                    <NumericBullets
                        description={description2}
                        img={image2}
                        title="Treatment Areas"
                        topicNotNeeded
                        hideTitleDesc
                        hideSubtitle
                        isReversed
                    />
                </div>
                <div className="py-2 md:py-5">
                    <NumericBullets
                        hideTitleDesc
                        topicNotNeeded
                        hideSubtitle
                        description={description}
                        img={image1}
                    />
                </div>
            </div>
        </>
    )
}

export default TreatmentAreas
