import React from 'react'
import bgimg from '../../assets/hair/AntiDandruff&HairFall.png'
import CommonHeading from '../common/commonHeading'


const HairRemoval = () => {
    return (
        <>
            <div className="relative w-full min-h-full ">
                <div className=" h-[85vh] sm:h-[80vh] md:h-[75vh] lg:h-[82vh] xl:h-full  min-h-full">
                    <img
                        src={bgimg}
                        alt="background"
                        className="min-w-full object-cover  h-full "
                    />
                </div>
                <div className="absolute inset-0 bg-[#353535]/40 h-[85vh] sm:h-full  min-h-full" />
                <CommonHeading
                    title="Laser Hair Removal with ND:YAG Laser"
                    Subtitle="“Say Goodbye to Unwanted Hair”"
                    description="Are you tired of shaving, waxing, or plucking unwanted hair? Laser hair removal offers a long-lasting solution that leaves your skin silky-smooth. At our aesthetic care center, we specialize in using ND:YAG laser technology for safe and effective hair removal."
                />
            </div>
        </>
    )
}
export default HairRemoval
