import React from 'react'
import ImageScale from '../common/reverseBolierPlate'
import image from '../../assets/laserHairRemoval/armpit-laser-hair-removal-procedure-woman-diode-laser-hand-beautician-hair-removal-female-beautiful-body.jpg'
import bgimage from '../../assets/laserHairRemoval/beautiful-caucasian-woman-shaving-her-legs-with-knife-with-shaving-foam-white-background.jpg'

const HairRemovalSection = () => {
    let data = [
        {
            title: 'How Does ND:YAG Laser Work?',
            text: `<div>ND:YAG (neodymium-doped yttrium aluminum garnet) lasers emit highly intense light beams that target hair follicles. The 1064 nm wavelength allows us to treat all skin types, including darker skin tones. Here’s how it works: <br/> <br/>
            <strong>Selective Targeting:</strong> The laser energy is absorbed by the melanin (pigment) in the hair follicles, effectively destroying them without harming the surrounding skin.
            <br/> <br/> 
            <strong>Hair Reduction:</strong> After treatment, the hair falls out, and further hair growth is minimized. Multiple sessions are usually required for optimal results.

            .</div>`,
            front: image,
            bgimg: bgimage,
        },
    ]
    return (
        <>
            <div className='py-2 md:py-10' id='nextSection'>
                <ImageScale heading='Laser Hair Removal' contentMatter={data} />
            </div>
        </>
    )
}

export default HairRemovalSection
