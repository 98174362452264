import React from 'react'
import Solutions from '../../assets/laserHairRemoval/laser-hair-removal.jpg'
import NumericBullets from '../common/NumericBullets'

const HairRemovalND = () => {
    const count = {
       
        countingArr2: [
            {
                id: 1,
                topic: 'Safe for All Skin Types',
                description:
                    'Unlike some other lasers, ND:YAG is safe for deep skin tones.',
            },
            {
                id: 2,
                topic: 'Minimal Discomfort',
                description:
                    'Most patients report only mild to moderate discomfort during treatment.',
            },
            {
                id: 3,
                topic: 'Quick Recovery',
                description:
                    'Recovery time is minimal, allowing you to resume your daily activities promptly.',
            },
            {
                id: 4,
                topic: 'Effective Results',
                description:
                    'Visible reduction in hair growth after each session.',
            },
        ],
    }

    return (
        <>
            <div className="mt-6 grid gap-y-[40px] sm:gap-y-16">
                {/* 3rd section */}
                <NumericBullets
                    title="Benefits of ND:YAG Laser Hair Removal"
                    hideSubtitle
                    hideTitleDesc
                    img={Solutions}
                    description={count.countingArr2}
                />
            </div>
        </>
    )
}

export default HairRemovalND
